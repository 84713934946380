<template>
    <div class="links">
        <a class="btn btn-link" href="/news"> {{ __('common.Blog')}}</a>
        <a class="btn btn-link" href="https://help.coachguitar.com/en/articles/7241616-terms-of-use"> {{__('common.Terms_of_use')}}</a>
        <a class="btn btn-link" href="https://help.coachguitar.com"> {{ __('common.Help_center')}}</a>
        <a class="btn btn-link" href="/gift"> {{ __('common.Gift_card')}}</a>
        <a class="btn btn-link" href="javascript:void(0)" @click="cookiesettings()" >{{ __('common.Cookie_settings')}}</a>
      </div>
</template>

<script>
export default {
    name: "footerlinks",
    data: () => ({
    }),
    mounted() {
        console.log("footerlinks monted!");
    },
    methods: {
        cookiesettings() {
            console.log("clichj")
            var CF = window.CookieFirst;
            CF.openPanel();
        },
    },
    computed: {
    }
}
</script>