<template>

<div class="dropup cg-dropdown discrete-drop ml-3">
    <button class="btn dropdown-toggle btn-block" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ selectedlang }}
    </button>
    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" >
        <a class="dropdown-item"  v-for="(key, value) in languageList" :key="value" :href="'/'+value+url"> {{key}} </a>
    </div>
</div>

</template>

<script>
export default {
    name: "langselector",
    data: () => ({
        selectedlang:'en',
        languageList : {
            "fr": "Français",
            "en": "English",
            "de": "Deutsch",
            "es": "Español",
            "it": "Italiano",
            "ja": "日本",
            "ko": "한국인",
            "pt": "Português",
            "pt-BR": "Brasileira",
            "ru": "Русский",
            "zh": "中国人"
            // "zh-hant": "Traditional Chinese"
        },
    }),
    mounted() {
        var path = window.location.pathname
        if (path.charAt(path.length - 1) !== '/') {
            path = path + "/";
        }
        console.log("LangSelector mounted() path: " + path);
        for(var key in this.languageList) {
            var tofind = "/" + key + "/";
            console.log("tofind=" + tofind);
            if (path.search(tofind) != -1) {
                console.log("found=" + this.languageList[key]);
                this.selectedlang = this.languageList[key];
            }
        }
    },
    methods: {
    },
    computed: {
        url: function () {
            console.log("computed " + url);
            var regex = RegExp("^\/[a-zA-Z]+");
            var url = window.location.pathname
            url = url.replace("-hant", "");
            url = url.replace("-BR", "");
            url = url.replace(regex, "");
            console.log(url)
            return url;
        },
    }
}
</script>
