import Vue from "vue";
import {Lang} from "laravel-vue-lang";
import langselector from "./../components/LangSelector.vue";
import footerlinks from "./../components/FooterLinks.vue"
import axios from "axios";


Vue.use(Lang, {
    fallback: "en"
});

var app = new Vue({
    el: "#landingfooter",
    name: "landingFooter",
    components: {
        langselector,
        footerlinks
    },
});

/*
var app2 = new Vue({
    name: "premiumPricingLanding",
    el: "#premium-pricing-landing",
    mixins: [],
    components: {},
    data: () => ({
        formattedMonthlyPrice: "$19,99",
        formattedYearlyPrice: "$9,99",
        yearlyPrice: "$59,99",
    }),
    mounted() {
        console.log("JUXX");
        var self = this;
        
        axios.get("/api/premium_products/yearly").then((response) => {

            self.formattedYearlyPrice = response.data;
            self.formattedMonthlyPrice = response.data;

        //    self.formattedYearlyPrice =
        //        (response.data.price.currency == "usd" ? "$" : "€") +
        //        (response.data.price.unit_amount / 100 / 12)
        //            .toString()
        //            .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        //    self.yearlyPrice = response.data.price.formatted;
            
        });

        axios.get("/api/premium_products/monthly").then((response) => {
            self.formattedMonthlyPrice = response.data.price.formatted
        });
    },
});
*/
